import React, { Component } from 'react';
import axios from 'axios';
import { ReactComponent as Logo } from '../svg/BeMyLove.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

library.add(
  faSignInAlt
)

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if( localStorage.getItem('bml_session_admin') ) {
      axios.post(process.env.REACT_APP_API_URL + '/login-session/', { token: localStorage.getItem('bml_session_admin') } )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              this.props.history.push('/index');
            }
            else {
              // NO
              this.setState({error: true});
            }
          });
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    if( this.state.value.length === 12 ) {
      axios.post(process.env.REACT_APP_API_URL + '/login-admin/', { login: this.state.value } )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              localStorage.setItem("bml_session_admin", response.data.token);
              this.props.history.push('/index');
            }
            else {
              // NO
              this.setState({error: true});
            }
          });
    }
    else {
      this.setState({error: true});
    }
    event.preventDefault();
  }

  render() {
    return (
      <div className="">
        <div id="login">
          <div>
            <div className="col-md-6 offset-md-3 text-center">
            <h3 className="text-center">Backoffice</h3>
              <Logo className="logo" width="250" fill="white" />
              <h1>bemylove</h1>
              <p>Il tuo wedding planner digitale!</p>
            </div>
          </div>
            <div className="">
              <div className="col-md-6 offset-md-3">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group pos-ipad">
                    <label htmlFor="access-app">Inserisci il Codice</label>
                    <input
                      type="password"
                      id="access-app"
                      className="form-control"
                      aria-describedby="Inserisci qui il Codice"
                      maxLength="12"
                      value={this.state.value}
                      onChange={this.handleChange} />
                    <p id="error-login" style={this.state.error ? { display: 'block' } : {} }>
                      Codice non corretto o account non validato.<br />
                    </p>
                  </div>
                  <div className="">
                    <div className="col-md-4 offset-md-3">
                      <button type="submit" className="btn-submit">Accedi <FontAwesomeIcon icon="sign-in-alt" /></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Login;
