import React, { Component } from 'react';
import axios from 'axios';
import Footer from '../partials/Footer';
import Header from '../partials/Header';
import { ReactComponent as Logo } from '../svg/BeMyLove.svg';
import Moment from 'react-moment';
import 'moment/locale/it';

import { Link } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/index';

library.add({
  faSyncAlt
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isMobile: window.innerWidth < 1000 ? true : false,
      update: false
    };
    document.body.classList.remove('back');

    this.renderHome = this.renderHome.bind(this);
    this.updateUsers = this.updateUsers.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if( localStorage.getItem('bml_session_admin') ) {
      let value = {
        token: localStorage.getItem("bml_session_admin")
      }
      axios.post(process.env.REACT_APP_API_URL + '/get-users-admin/', value )
          .then(response => {
            if(response.data.error.status === false) {
              this.setState({
                users: response.data.rls
              });
            }
            else {
              localStorage.clear();
              this.props.history.push(
                '/login'
              );
            }
          });
    }
    else {
      localStorage.clear();
      this.props.history.push(
        '/login'
      );
    }
  }

  updateUsers() {
    this.componentDidMount();
    this.setState({
      update: true
    });
    setTimeout( () => {
      this.setState({
        update: false
      });
    }, 3000);
  }

  renderHome() {
    if( this.state.isMobile ) {
      return <div>
        <div>
        { this.state.users.map( (user, key) => {
              return (
                <div className="single-user">
                  <small><strong>Nomi sposi</strong></small><span className="user-id">{ user.id }</span><br />
                  { user.sposo } & { user.sposa }<br />
                  <small><strong>Email</strong></small><br />
                  { user.email }<br />
                  <small><strong>OTP?</strong></small><br />
                  { user.otp == 1 ? 'Sì' : 'No' }<br />
                  <small><strong>Data registrazione</strong></small><br />
                  <Moment format="L LT" locale="it">
                    { user.createdAt }
                  </Moment>
                </div>
            );
            }) }
        </div>
      </div>;
    }
    else {
      return <table className="table" style={ this.state.isMobile ? { display: 'none' } : { display: 'block' } }>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Sposo</th>
            <th scope="col">Sposa</th>
            <th scope="col">Email</th>
            <th scope="col">Account<br />convalidato</th>
            <th scope="col">Registrato il</th>
            <th scope="col">Visualizza</th>
          </tr>
        </thead>
        <tbody>
        { this.state.users.map( (user, key) => {
              return (
                <tr key={ key }>
                  <th scope="row">{ user.id }</th>
                  <td>{ user.sposo }</td>
                  <td>{ user.sposa }</td>
                  <td>{ user.email }</td>
                  <td>{ user.otp == 1 ? 'Sì' : 'No' }</td>
                  <td>
                    <Moment format="L LT" locale="it">
                      { user.createdAt }
                    </Moment>
                  </td>
                  <td>Vedi</td>
                </tr>
            );
            }) }
        </tbody>
      </table>
    }
  }

  render() {
    return (
      <div className="App">
        <Header ctrl="0" index="0" />
        <div className="bg-white">
          <div className="col-lg-12">
            <h3>Lista iscritti <span style={{float: 'right'}}><FontAwesomeIcon icon="sync-alt" onClick={ (e) => this.updateUsers() } /></span></h3>
            <p className="list-update" style={ this.state.update ? { display: 'block' } : { display: 'none' }}>Lista aggiornata</p>
            { this.renderHome() }

          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
