import React, { Component } from 'react';
import axios from 'axios';
import Footer from '../partials/Footer';
import Header from '../partials/Header';
import { ReactComponent as Logo } from '../svg/BeMyLove.svg';
import Moment from 'react-moment';
import 'moment/locale/it';

import { Link } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/index';

library.add({
  faEnvelope
});


class SendEmails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      show: false,
      email: '',
      name: '',
      title: '',
      type: 0,
      sendEmailResponse: '',
      isMobile: window.innerWidth < 1000 ? true : false
    };
    document.body.classList.remove('back');

    this.ctrlSend = this.ctrlSend.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submit = this.submit.bind(this);
    this.renderSendEmails = this.renderSendEmails.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if( localStorage.getItem('bml_session_admin') ) {
      axios.post(process.env.REACT_APP_API_URL + '/get-send-email-admin/', { token: localStorage.getItem('bml_session_admin') } )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              this.setState({
                users: response.data.rls
              });
            }
            else {
              // NO
              localStorage.clear();
              this.props.history.push(
                '/login'
              );
            }
          });
    }
    else {
      localStorage.clear();
      this.props.history.push(
        '/login'
      );
    }
  }

  ctrlSend( user, col ) {
    switch (col) {
      case 1:
        if( user.SendEmails.length > 0 && user.SendEmails[0].survey1 ) {
          return <div className="text-center">
                  <FontAwesomeIcon icon="envelope" onClick={ (e) => this.handleShow( user, 'Survey', col ) } /><br /><small>Inviata il</small><br />
                  <Moment format="L LT" locale="it">
                    { user.SendEmails[0].survey1 }
                  </Moment>
                 </div>
        }
        else {
          return  <div className="text-center">
                    <FontAwesomeIcon icon="envelope" onClick={ (e) => this.handleShow( user, 'Survey', col) } />
                    <p>Non inviata</p>
                  </div>
        }
          break;
      case 2:
        if( user.SendEmails.length > 0 && user.SendEmails[0].unsubscribe ) {
          return  <div className="text-center">
                    <FontAwesomeIcon icon="envelope" onClick={ (e) => this.handleShow( user, 'Cancellazione', col ) } /><br /><small>Inviata il</small><br />
                    <Moment format="L LT" locale="it">
                      { user.SendEmails[0].unsubscribe }
                    </Moment>
                   </div>
        }
        else {
          return  <div className="text-center">
                    <FontAwesomeIcon icon="envelope" onClick={ (e) => this.handleShow( user, 'Cancellazione', col ) } />
                    <p>Non inviata</p>
                  </div>
        }
      default:
        break;
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow( user, title, col ) {
    this.setState({
      show: true,
      email: user.email,
      title: title,
      type: col,
      name: user.sposo + ' e ' + user.sposa,
      user_id: user.id
     });
     return 0;
  }

  submit() {

    let input = {
      token: localStorage.getItem("bml_session_admin"),
      email: this.state.email,
      type: this.state.type,
      name: this.state.name,
      user_id: this.state.user_id
    };

      axios.post(process.env.REACT_APP_API_URL + '/send-email-comunication/', input)
        .then(response => {
          console.log(response);
          if(response.data.error.status === false) {
            axios.post(process.env.REACT_APP_API_URL + '/send-email-survey-admin/', input)
              .then(responseSave => {
                  this.setState({
                    sendEmailResponse: response.data.rls,
                    show: false
                  });

                  setTimeout( () => {
                    window.location.reload(false);
                  }, 2000);

              });
          }
          else {
            this.setState({
              sendEmailResponse: response.data.error.rls,
              show: false
            });
          }
        });

        return 0;
  }

  renderSendEmails() {
    if( this.state.isMobile ) {
      return <div>
      { this.state.users.map( (user, key) => {
            return (
              <div className="single-user send-emails">
                <small><strong>Email</strong></small><br />
                { user.email }<br />
                <small><strong>Survey</strong></small><br />
                { this.ctrlSend( user, 1 ) }<br />
                <small><strong>Cancellazione</strong></small><br />
                { this.ctrlSend( user, 2 ) }<br />
              </div>
          );
          }) }
      </div>
    }
    else {
      return <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Email</th>
            <th scope="col">OTP?</th>
            <th scope="col" className="text-center">Survey</th>
            <th scope="col" className="text-center">Cancellazione</th>
          </tr>
        </thead>
        <tbody>
        { this.state.users.map( (user, key) => {
              return (
                <tr key={ key }>
                  <th scope="row">{ user.id }</th>
                  <td>{ user.email }</td>
                  <td>{ user.otp == 1 ? 'Sì' : 'No' }</td>
                  <td>{ this.ctrlSend( user, 1 ) }</td>
                  <td>{ this.ctrlSend( user, 2 ) }</td>
                </tr>
            );
            }) }
        </tbody>
      </table>
    }
  }

  render() {
    return (
      <div className="App">
        <Header ctrl="0" index="1" />
        <div className="bg-white">
          <h3>Lista iscritti</h3>
          <p>{ this.state.sendEmailResponse }</p>
          { this.renderSendEmails() }
        </div>
        <Footer />
        <div className="modal show-modal" style={ this.state.show ? { display: 'block' } : { display: 'none' } }>
          <div className="modal-content">
            <span className="close-button" onClick={this.handleClose}>&times;</span>
              <h3>Invio email { this.state.title }</h3>
              Vuoi davvero inviare l'email a { this.state.email }
              <br /><br />
              <div className="">
                <div className="col-md-4 offset-md-4">
                  <button type="button" className="btn-submit" onClick={ (e) => this.submit() }>Invia</button>
                </div>
              </div>

          </div>
        </div>
      </div>
    );
  }
}

export default SendEmails;
