import React, { Component } from 'react';

import { Link } from "react-router-dom";

class Footer extends Component {

  render() {
    return (
      <div id="footer" style={{ display: 'none' }}>
        footer
      </div>
    );
  }
}

export default Footer;
