import React, { Component } from 'react';
import axios from 'axios';
// import Footer from '../partials/Footer';
import Header from '../partials/Header';
import { ReactComponent as Logo } from '../svg/BeMyLove.svg';

import { Link } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faPlus, faPencilAlt } from '@fortawesome/free-solid-svg-icons/index';

library.add({
  faPlus,
  faPencilAlt
});

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      isMobile: window.innerWidth < 1000 ? true : false,
      update: false
    };
    document.body.classList.remove('back');

    this.renderBlog = this.renderBlog.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if( localStorage.getItem('bml_session_admin') ) {
      let value = {
        token: localStorage.getItem("bml_session_admin")
      }
      axios.post(process.env.REACT_APP_API_URL + '/get-blogs/', value )
          .then(response => {
            if(response.data.error.status === false) {
              this.setState({
                blogs: response.data.rls
              });
            }
            else {
              localStorage.clear();
              this.props.history.push(
                '/login'
              );
            }
          });
    }
    else {
      localStorage.clear();
      this.props.history.push(
        '/login'
      );
    }
  }

  renderBlog() {
    if( this.state.isMobile ) {
      return <div>
      { this.state.blogs.map( (blog, key) => {
            return (
              <div className="single-user send-emails">
                <small><strong>Titolo</strong></small><br />
                { blog.title_it }<br />
                <small><strong>Status</strong></small><br />
                { blog.status == 1 ? 'online' : 'offline' }<br />
              </div>
          );
          }) }
      </div>
    }
    else {
      if( this.state.blogs.length == 0 ) {
        return 'Nessuna informazione da visualizzare';
      }
      return <table className="table">
        <thead>
          <tr>
            <th scope="col">Titolo</th>
            <th scope="col">Status</th>
            <th scope="col" className="text-center">Modifica</th>
            <th scope="col" className="text-center">Cancella</th>
          </tr>
        </thead>
        <tbody>
        { this.state.blogs.map( (blog, key) => {
              return (
                <tr key={ key }>
                  <td>{ blog.title_it }</td>
                  <td>{ blog.status == 1 ? 'online' : 'offline' }</td>
                  <td><Link to={ '/modifica-articolo/' + blog.id } className="text-center"><FontAwesomeIcon icon="pencil-alt" /></Link></td>
                  <td>#</td>
                </tr>
            );
            }) }
        </tbody>
      </table>
    }
  }

  render() {
    return (
      <div className="App">
        <Header ctrl="0" index="2" />
        <div className="bg-white">
          <div className="col-lg-12">
            <h3>Blog <Link to="/articolo" style={{float: 'right'}}><FontAwesomeIcon icon="plus" /></Link></h3>
            { this.renderBlog() }
          </div>
        </div>

      </div>
    );
  }
}

export default Blog;
