import React, { Component } from 'react';
import axios from 'axios';
import Header from '../partials/Header';
import { ReactComponent as Logo } from '../svg/BeMyLove.svg';

import { Link } from "react-router-dom";

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: [],
      isMobile: window.innerWidth < 1000 ? true : false,
      update: false,
      image: "",
      nameImage: "",
      images: [],
      nameImages: [],
      hideImage: true,
      oldImage: false,
      bigImage: false,
      countTitleIt: 0,
      countTitleEn: 0,
      countAbstractIt: 0,
      countAbstractEn: 0,
      url: "",
      status: false,
      id: props.match.params.id ? props.match.params.id : 0
    };
    document.body.classList.remove('back');

    this.getImage = this.getImage.bind(this);
    this.getImages = this.getImages.bind(this);
    this.countTitle = this.countTitle.bind(this);
    this.countAbstract = this.countAbstract.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if( this.state.id > 0 ) {
      if( localStorage.getItem('bml_session_admin') ) {
        let value = {
          token: localStorage.getItem("bml_session_admin"),
          id: this.state.id
        }
        axios.post(process.env.REACT_APP_API_URL + '/edit-article/', value )
            .then(response => {
              if(response.data.error.status === false) {
                this.setState({
                  article: response.data.rls,
                  image: process.env.REACT_APP_MEDIA_URL + 'blog/' + response.data.rls.id + '/' + response.data.rls.cover,
                  nameImage: response.data.rls.cover,
                  hideImage: false,
                  countTitleIt: response.data.rls.title_it.length,
                  countTitleEn: response.data.rls.title_en.length,
                  countAbstractIt: response.data.rls.abstract_it.length,
                  countAbstractEn: response.data.rls.abstract_en.length,
                  status: response.data.rls.status,
                  url: response.data.rls.status
                });
                this.refs.title_it.value = response.data.rls.title_it;
                this.refs.title_it.value = response.data.rls.title_it;
                this.refs.abstract_it.value = response.data.rls.abstract_it;
                this.refs.abstract_en.value = response.data.rls.abstract_en;
                this.refs.text_it.value = response.data.rls.text_it;
                this.refs.text_en.value = response.data.rls.text_en;
                this.refs.url.value = response.data.rls.url;
                console.log(this.state);
              }
              else {
                localStorage.clear();
                this.props.history.push(
                  '/login'
                );
              }
            });
      }
      else {
        localStorage.clear();
        this.props.history.push(
          '/login'
        );
      }
    }

  }

  handleSubmit(event) {
    this.setState({
      loadInfo: true,
      waitSave: true
    });
    let input = {
      id: this.state.id,
      title_it: this.refs.title_it.value,
      title_en: this.refs.title_en.value,
      abstract_it: this.refs.abstract_it.value,
      abstract_en: this.refs.abstract_en.value,
      text_it: this.refs.text_it.value,
      text_en: this.refs.text_en.value,
      status: false,
      image: this.state.image,
      nameImage: this.state.nameImage,
      images: this.state.images,
      nameImages: this.state.nameImages,
      oldImage: false,
      bigImage: false,
      url: this.refs.url.value,
      status: this.state.status,
      token: localStorage.getItem("bml_session_admin")
    };

    axios.post(process.env.REACT_APP_API_URL + '/article-manage/', input)
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            this.props.history.push('/blog');
          }
          else {
            // NO
          }

        });
    event.preventDefault();
  }

  getImage( event ) {
    let img = document.querySelector('#preview');
    var img1 = document.querySelector('#preview-1');
    var files = event.target.files;
    if(files[0].size > 5000000) {

      img1.src = "";
      this.setState({
        bigImage: true,
        oldImage: false,
        hideImage: false
      });
      return;
    }
    else {
      this.setState({
        oldImage: true,
        bigImage: false,
        hideImage: false
      });
    }
    if (files.length > 0) {
      var file = files[0];
      if(file.name.indexOf('.') == -1) {
        return;
      }

      var reader = new FileReader();
      reader.onload = (function (aImg, aImg1) {
        return function (e) {
          aImg1.src = e.target.result;
          aImg.style.backgroundImage  = 'url('+e.target.result+')';
        };
      })(img, img1);

      reader.readAsDataURL(file);

      setTimeout( () => {
        this.setState({
          image: reader.result.replace(/^data:image\/png;base64,/, "").replace(/^data:image\/jpeg;base64,/, "").replace(/^data:image\/jpg;base64,/, ""),
          nameImage: file.name
        });
      }, 1500);
    }

  }

  getImages( event ) {
    let files = event.target.files;
    let images = [];
    let namesImages = [];

    console.log(files);

    for( let i = 0; i < files.length; i++ ) {
      let file = files[i];
      if(file.size > 5000000) {
        this.setState({
          bigImage: true,
          oldImage: false,
          hideImage: false
        });
        return;
      }
      else {
        this.setState({
          oldImage: true,
          bigImage: false,
          hideImage: false
        });
      }

      if(file.name.indexOf('.') == -1) {
        return;
      }

      var reader = new FileReader();
      reader.onload = function(event) {
      	return event.target.result;
      };

      reader.readAsDataURL(file);

      setTimeout(() => {
        images.push(reader.result.replace(/^data:image\/png;base64,/, "").replace(/^data:image\/jpeg;base64,/, "").replace(/^data:image\/jpg;base64,/, ""));
        namesImages.push(file.name);
        console.log(namesImages);
      }, 500);
    }
    setTimeout(() => {
      this.setState({
        images: images,
        nameImages: namesImages
      });
    }, 5000);

  }

  countTitle( lang ) {
    if( lang == 'it' ) {
      let title = this.refs.title_it.value;
      this.setState({
        countTitleIt: title.length
      });
    }
    else {
      this.setState({
        countTitleEn: this.refs.title_en.value.length
      });
    }
  }

  countAbstract( lang ) {
    if( lang == 'it' ) {
      this.setState({
        countAbstractIt: this.refs.abstract_it.value.length
      });
    }
    else {
      this.setState({
        countAbstractEn: this.refs.abstract_en.value.length
      });
    }
  }

  setStatus() {
    let status = this.state.status;
    if( status === true ) {
      this.state.status = false;
    }
    else {
      this.state.status = true;
    }
  }

  render() {
    return (
      <div className="App Article">
        <Header ctrl="0" index="2" />
        <div className="bg-white">
          <div className="col-lg-12">
            <h3>Articolo</h3>
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="title_it">Titolo IT</label>
                    <input ref="title_it" type="text" id="title_it" aria-describedby="Titolo" required onKeyUp={ (e) => this.countTitle('it') } />
                    <small>Tra i 30 e i 70 caratteri. <span style={ { float: 'right' } }><strong>Caratteri: { this.state.countTitleIt }</strong></span></small>
                    <br /><label htmlFor="title_en">Titolo EN</label>
                    <input ref="title_en" type="text" id="title_en" aria-describedby="Titolo" required onKeyUp={ (e) => this.countTitle('en') } />
                    <small>Tra i 30 e i 70 caratteri. <span style={ { float: 'right' } }><strong>Caratteri: { this.state.countTitleEn }</strong></span></small>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="data">Immagine di copertina</label>
                    <input type="file" name="file" id="file" className="" onChange={ this.getImage } />
                  </div>
                  <div className="form-group">
                    <label htmlFor="data">Immagini interne</label>
                    <input type="file" name="files" id="files" className="" onChange={ this.getImages } multiple />
                  </div>
                </div>
                <div className="col-lg-12 text-center" style={ this.state.hideImage ? { display: 'none'} : { display: 'block'} }>
                  <div id="preview" className="image" style={ this.state.oldImage == true ? { display: 'none'} : { display: 'block'} }></div>
                  <img src="" id="preview-1" className="chat-img" width="250" />
                  <div style={ !this.state.bigImage ? { display: 'none'} : { display: 'block'} }>
                    Immagine troppo grande.
                  </div>
                  <br /><br />
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="abstract_it">Abstract IT</label>
                    <input ref="abstract_it" type="text" id="abstract_it" aria-describedby="Abstract" required onKeyUp={ (e) => this.countAbstract('it') } />
                    <small>Massimo 160 caratteri. <span style={ { float: 'right' } }><strong>Caratteri: { this.state.countAbstractIt }</strong></span></small>
                    <br /><label htmlFor="abstract_en">Abstract EN</label>
                    <input ref="abstract_en" type="text" id="abstract_en" aria-describedby="Abstract" required onKeyUp={ (e) => this.countAbstract('en') } />
                    <small>Massimo 160 caratteri. <span style={ { float: 'right' } }><strong>Caratteri: { this.state.countAbstractEn }</strong></span></small>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="text_it">Testo IT</label>
                    <textarea ref="text_it" id="text_it" rows="10"></textarea>
                    <label htmlFor="text_en">Testo EN</label>
                    <textarea ref="text_en" id="text_en" rows="10"></textarea>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="url">Url</label>
                    <input ref="url" type="text" id="url" aria-describedby="Url" required />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Online/Offline</label><br />
                    <input ref="status" type="checkbox" id="status" aria-describedby="Status" onChange={ (e) => this.setStatus() } checked={ this.state.status }/> <span>Se selezionato è online.</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 center-single-btn">
                <button type="submit" className="btn-submit">Salva</button>
              </div>
              <br /><br />
            </form>
          </div>
        </div>

      </div>
    );
  }
}

export default Article;
