import React, { Component } from 'react';
import axios from 'axios';

import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../svg/BeMyLove.svg';

class Header extends Component {
  constructor(props) {

    super(props);

    this.state = {
      isMobile: window.innerWidth < 1000 ? true : false,
      index: props.index,
      menu: false,
    };

    this.logout = this.logout.bind(this);
    this.openMenu = this.openMenu.bind(this);

  }

  componentDidMount() {
    window.addEventListener("scroll", (event) => {

    });
  }

  openMenu() {
    this.setState({
      menu: !this.state.menu
    });
  }

  logout() {
    let input = {
      token: localStorage.getItem("bml_session_admin")
    };

    axios.post(process.env.REACT_APP_API_URL + '/logout/', input)
        .then(response => {
          localStorage.removeItem("bml_session_admin");
          window.top.location.href = '/index';
        });
  }

  render() {
    return (
      <div>
        <nav id="nav-admin" className="">
          <Link to="/">
            <Logo className="logo" width="50" fill="#FFF" /> bemylove
          </Link>
          <div className="content-nav-brand" style={ this.state.isMobile ? { display: 'flex' } : { display: 'none' } }>
            <span id="nav-bar" className={this.state.menu ? 'change navbar-toggler start' : 'navbar-toggler start'} onClick={this.openMenu}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </span>
          </div>
          <ul className={ this.state.isMobile ? 'nav-mobile' : 'nav-desktop' } style={ this.state.menu ? { display: 'block' } : this.state.isMobile ? { display: 'none' } : { display: 'block' } }>
            <li className={ this.state.index == 0 ? 'active' : '' }>
              <Link to="/">
                Home
              </Link>
            </li>
            <li className={ this.state.index == 1 ? 'active' : '' }>
              <Link to="/invio-email">
                Invio email
              </Link>
            </li>
            <li className={ this.state.index == 2 ? 'active' : '' }>
              <Link to="/blog">
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <span onClick={(e) => this.logout()}>Logout</span>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Header;
